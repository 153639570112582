
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class EventModal extends Vue {
  @Prop({ default: false })
  public active = false

  handleClick(): void {
    this.active = false;
    this.$emit('got_active', this.active);
  }
}

