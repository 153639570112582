




































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import EventModal from '../components/EventModal.vue';

@Component({
  components: {
    EventModal,
  },
})
export default class Events extends Mixins(View) {
  public dialogOpen = false;

  public activeSpeaker = 0;

  ticketsLink = "https://www.eventbrite.com.au/e/exploration-day-2024-tickets-851137223347";

  speakers = [
    {
      name: 'LAUREN CLEMETT',
      splitName: 'LAUREN<br />CLEMETT',
      job: 'THE BRAND NAVIGATOR',
      img: '/assets/images/events/lauren.png',
      about: `The Brand Navigator, Lauren Clemett is
          an award-winning Neurobranding expert.
          Told as a child that she had wordblindness
          and would never be able to read or write
          properly, she went on to become a 5 time
          best selling author and now uses her
          dyslexia as her greatest asset - helping others
          understand how the brain sees brands. She
          has worked at leading advertising agencies
          and in brand management since before the
          internet, helping launch hundreds of global
          brands and appears in worldwide media, as
          the sought after personal branding specialist.
          Lauren shares how to overcome overwhelm
          and get a clear direction to market and
          promote professional and business services,
          to stand out from the crowd as well known,
          well-paid and wanted leaders.`,
    },
    {
      name: 'KASEY MCDONALD',
      splitName: 'KASEY<br />MCDONALD',
      job: 'CORELOGIC QLD STATE<br />LEADER, SALES – REAL ESTATE',
      img: '/assets/images/events/kasey.png',
      about: `Kasey is a seasoned leader with over 26
          years of experience in the real estate
          industry and as someone who has
          owned and operated an agency, she
          possesses a deep understanding of the
          day-to-day operations of real estate
          agencies and is adept at leading and
          managing high-performing teams.
          <br/><br/>
          In her current position as the QLD State
          Manager at CoreLogic, Kasey stands
          at the forefront of catalysing revenue
          growth by fostering high-performance
          among sales teams through the
          steadfast implementation of operational
          practices. Her strategic methodology in
          deploying growth strategies seamlessly
          aligns with the demands of her role,
          consistently propelling individuals to
          achieve success and ascend to the status
          of high performers.
          <br/><br/>
          Kasey’s influence extends beyond
          her current role, as evidenced by her
          recognition as a Top 50 Industry
          Influencer and her distinguished
          position among the Top 24 Female
          Trainers in Real Estate.
          <br/><br/>
          Her impact is evident in the
          transformation of over 250 real estate
          offices globally, where she introduced
          superior operational and growth
          practices, coupled with strategic
          restructuring.`,
    },
    { name: 'SHANE HICKS',
      splitName: 'SHANE<br />HICKS',
      job: 'PARTNER & LEAD AGENT<br />PLACE ESTATE AGENTS BRISBANE',
      img: '/assets/images/events/shane.png',
      about: `With over 10 years of experience, Shane
          Hicks has earned his position in the Top
          5% of Real Estate Agents in Australia.
          <br/><br/>
          An auction master, Shane holds a long
          list of suburb and street sale records,
          with an impressive 90% sell-to-list ratio
          and a total of $10 million in over-reserve
          dollars. As the #1 team in Camp Hill for
          five years running , The Hicks Team is
          proud to have helped more property
          owners achieve their property goals
          than any other team in the area.
          <br/><br/>
          Shane is well-known for not just his track
          record, but also taking the stress out
          of selling. With his lighthearted nature
          and down-to-earth attitude, he can help
          you facilitate a property sale that is both
          profitable and seamless. You might even
          have a bit of fun along the way!
          <br/><br/>
          Passionate about giving back, Shane
          uses his expertise to train and mentor
          the next generation of up-andcoming
          agents, speaking at a variety
          of conferences across Australia and
          regularly contributing to training within
          the Place group.` },

    { name: 'JOHN FOONG',
      splitName: 'JOHN<br />FOONG',
      job: 'DOMAIN CHIEF REVENUE OFFICER <br />& MANAGING DIRECTOR - AGENTS',
      img: '/assets/images/events/john.png',
      about: `John joined Domain as Chief Revenue
          Officer in September 2021, having
          previously held senior leadership roles at
          Uber, Google and McKinsey & Co. John
          has focused on accelerating Domain’s
          marketplace strategy leveraging Domain
          mantra of “Better Together”.
          <br/><br/>
          John’s focus is helping agents build
          sustainable and effective businesses by
          offering a set of connected solutions
          to supercharge agents workflow to
          complete today’s tasks in half the time.
          <br/><br/>
          He was ranked #36 in Elite Agent’s “Most
          Influential leaders in Australian real
          estate” at the end of 2022.
          <br/><br/>
          John previously served as Global
          Director of Account Management
          and Customer Engineering at Uber in
          California where he led multiple growth,
          collaboration and partnership initiatives
          across Uber’s business customers.
          <br/><br/>
          Prior to Uber, John spent 12 years at
          Google holding a range of go-to-market
          leadership roles, including leading
          Google Cloud’s multi-billion dollar
          global partner/agent sales team.`},
    { name: 'KYLIE BARTLETT',
      splitName: 'KYLIE<br />BARTLETT',
      job: 'LIFE & BUSINESS STRATEGIST<br /> MIND-BODY LEADERSHIP<br /> COACH & WELLNESS ADVOCATE',
      img: '/assets/images/events/kylie.png',
      about: `Kylie Bartlett driven by an unwavering
              dedication to help humans lead, love,
              leap to potential.
              <br/><br/>
              Founder of Lead Love Leap Coaching
              & Consultancy, creator of the Live Big
              Mastermind, Reset & Roar Membership
              for Female Founders and Women of
              Achievement Events, Kylie helps her clients
              reshape narratives of success in alignment
              to their deepest desires and values, teaches
              evidenced based and practical tools to
              cut through the BS in life and business,
              skilfully manage stress, turn challenges
              into opportunities, ignite innovation
              and resilience and enhance performance
              and growth without sacrificing the
              nervous system.
              <br/><br/>
              Kylie believes that leading ourselves is the
              most important job in the world where longlasting
              impact and legacy happens.`,
            notes: `CLIENT LOVE NOTES...
              <br /><br />
              <p style='line-height: 1.25em; padding-bottom: 0.5em; font-size: 0.8em; font-family: Ivy Presto Regular, sans-serif;'>“Professional conversations with
              you Kylie, are powerful, meaningful
              and transformative.”</p>
              Luke Bailis, Head of The Cathedral School
              <br /><br />
              <p style='line-height: 1.25em; padding-bottom: 0.5em; font-size: 0.8em; font-family: Ivy Presto Regular, sans-serif;'>“You got answers out of me
              I didn’t even know I had.”</p>
              Tina Maher, Artist
              <br /><br />
              <p style='line-height: 1.25em; padding-bottom: 0.5em; font-size: 0.8em; font-family: Ivy Presto Regular, sans-serif;'>“Wonderful professional
              development, very skilfully
              and engagingly facilitated.”</p>
              Sue Wilkinson, Regional Manager, Inclusion Agency QLD`},
  ];

  openSpeaker(speaker: number): void {
    this.activeSpeaker = speaker;
    this.dialogOpen = true;
  }
}
