import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Events from '../views/Events.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Events,
  },
  {
    path: '/events',
    name: 'Events',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/Events.vue'),
  },
  {
    path: '/learn',
    name: 'Learn',
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/people',
    name: 'People',
    component: () => import('../views/People.vue'),
  },
  {
    path: '/offices',
    name: 'Offices',
    component: () => import('../views/OfficeLocations.vue'),
  },
  {
    path: '/join-us',
    name: 'Join Us',
    component: () => import('../views/Franchise.vue'),
  },
  {
    path: '/franchise',
    name: 'Franchise',
    component: () => import('../views/Franchise.vue'),
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import('../views/Careers.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/seller',
    name: 'Seller',
    component: () => import('../views/Seller.vue'),
  },
  {
    path: '/landlord',
    name: 'Landlord',
    component: () => import('../views/Landlord.vue'),
  },
  {
    path: '/forms/request-an-appraisal',
    name: 'Request an Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/calculators',
    name: 'Calculators',
    meta: {
      hide: true,
    },
    component: () => import('../views/Calculators.vue'),
  },
  {
    // Listing search page (with the map)
    path: '/listings/:type(for-sale|land-for-sale|for-rent|commercial|sold|leased)',
    name: 'Listings',
    meta: {
      hideOfficeMap: true,
    },
    component: () => import('../views/Listings.vue'),
  },
  {
    // Listing details page
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Listing',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  // Hidden
  {
    path: '/promo',
    name: 'Promotions',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogPromo.vue'),
  },
  {
    // Listing details page
    path: '/blogs/:id',
    name: 'Blog',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/people/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/people/:id/properties/sold',
    name: 'Profile Properties Sold',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/people/:id/properties',
    name: 'Profile Properties',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/offices/head-office',
    name: 'Head Office',
    meta: {
      hide: true,
    },
    component: () => import('../views/HeadOfficeDetails.vue'),
  },
  {
    path: '/offices/:id',
    name: 'Office',
    meta: {
      hide: true,
    },
    component: () => import('../views/OfficeDetails.vue'),
  },
  {
    path: '/offices/sturt-street',
    name: 'Office',
    meta: {
      hide: true,
    },
    component: () => import('../views/OfficeDetails.vue'),
  },
  {
    path: '/offices/bundock-street',
    name: 'Office',
    meta: {
      hide: true,
    },
    component: () => import('../views/OfficeDetails.vue'),
  },
  {
    path: '/view-appraisal-request',
    name: 'Appraisal',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/ViewAppraisalRequest.vue'),
  },
  {
    path: '/appraisals/:name/:id',
    name: 'Appraisal',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/ViewAppraisal.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    meta: {
      hide: true,
    },
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/join',
    name: 'Join Us',
    component: () => import('../views/Franchise.vue'),
    meta: {
      hide: true,
      hideAncillaries: true, // Disables the header and footer
    },
  },
  {
    path: '/online-business-showcase-booking',
    name: 'Online Business Showcase Booking',
    component: () => import('../views/OnlineBusinessShowcaseBooking.vue'),
  },
  {
    path: '/forms/townsville/tenancy-application',
    name: 'Townsville Tenancy Application',
    component: () => import('../views/Forms/Townsville/TenancyApplicationForm.vue'),
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
  },
  {
    path: '/forms/bundaberg-region/tenancy-application',
    name: 'Bundaberg Region Tenancy Application',
    component: () => import('../views/Forms/BundabergRegion/TenancyApplicationForm.vue'),
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
  },
  {
    path: '/forms/moreton-bay-region/tenancy-application',
    name: 'Moreton Bay Region Tenancy Application',
    component: () => import('../views/Forms/MoretonBayRegion/TenancyApplicationForm.vue'),
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      if (to.fullPath) {
        API.Slugs.Resolve(to.fullPath.split(' ').join('-')).then((slug) => {
          switch (slug.Type) {
            case 'Listing':
              next({
                path: `/listings/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Profile':
              next({
                path: `/people/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Office':
              API.Offices.Get(slug.Id).then((office) => {
                next({
                  path: `/offices/${office.OfficeSlug}`,
                  replace: true,
                });
              }).catch(() => {
                next();
              });
              break;
            default:
              next();
              break;
          }
        }).catch(() => {
          next();
        });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (from && from.path === to.path) return null;

    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
